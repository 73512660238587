import React, { Component, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


import Landing from './components/layout/Landing';
import Login from './components/layout/Login';
import Register from './components/layout/Register';
import LoadingScreen from './LoadingScreen';

import './App.css';


const App = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 3000)
  }, [])
  return (
    <Router>
    {loading && <LoadingScreen />}
    {
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
      </Routes>
    }
    </Router>
  );
};

export default App;
