import React, { Component, Fragment  } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
  render() {
    return (
      <Fragment>
        <section className="footer">
        <div className="footer-content">
          <p>Copyright 2018 - 2023: See-Docs & Thenavigo, LLC. Atlas Founders: Stripe</p>

          <div className="icons">
            <a href="#"><i className="bx bxl-facebook-circle"></i></a>
            <a href="#"><i className="bx bxl-twitter"></i></a>
            <a href="#"><i className="bx bxl-instagram-alt"></i></a>
            <a href="#"><i className="bx bxl-youtube"></i></a>
          </div>
        </div>

        <div className="footer-content">
          <h4>Company</h4>
          <li><a href="#">About</a></li>
          <li><a href="#">Investors</a></li>
          <li><a href="#">Blog</a></li>
          <li><a href="#">FAQ</a></li>
          <li><a href="#">Thenavigo R&D</a></li>
          <li><a href="#">Careers</a></li>
        </div>

        <div className="footer-content">
          <h4>Products</h4>
          <li><a href="#">Give Feedback</a></li>
          <li><a href="#">Store</a></li>
          <li><a href="#">Tools</a></li>
          <li><a href="#">Mobile Apps</a></li>
          <li><a href="#">Technology</a></li>
          <li><a href="#">For Business</a></li>
          <li><a href="#">See-Docs</a></li>
        </div>

        <div className="footer-content">
          <h4>Handbook</h4>
          <li><a href="#">Our story</a></li>
          <li><a href="#">Benchmarking</a></li>
          <li><a href="#">How we work</a></li>
          <li><a href="#">Values</a></li>
          <li><a href="#">People & Ops</a></li>
          <li><a href="#">Open source</a></li>
        </div>

        <div className="footer-content">
          <h4>Docs</h4>
          <li><a href="#">Privacy</a></li>
          <li><a href="#">Conditions</a></li>
          <li><a href="#">Terms</a></li>
          <li><a href="#">Code of Conduct</a></li>
          <li><a href="#">Customer Support</a></li>
          <li><a href="#">Technical Docs</a></li>
          <li><a href="#">Security & Compliance</a></li>
        </div>
      </section>


      </Fragment>

      )
    }
  }

export default Footer;



 