import React, { Component, Fragment  } from 'react';
import { Link } from 'react-router-dom';
import "./login.css";

class Register extends Component {
  constructor() {
    super()
    this.state = {
      name: "",
      email: "",
      password: "",
      error: "",
      open: false
    };
  }


  handleChange = name => event => {
    this.setState({error: ""})
    this.setState({[name]: event.target.value})
  };

  clickSubmit = event => {
    event.preventDefault()
    const {name, email, password} = this.state
    const user = {
      name,
      email,
      password,
    };
    //console.log(user);
    this.signup(user)
      .then(data => {
        if(data.error) this.setState({error: data.error})
          else this.setState({
            error: "",
            name: "",
            email: "",
            password: "",
            open: true
          });
      });
  };

  signup = (user) => {
    return fetch("https://www.thenavigo.com/signup", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(user)
    })
      .then(response => {
        return response.json()
      })
      .catch(err => console.log(err))
  };


	render() {  
	return (
    <Fragment>
        <div className="split-screen">
    <div className="left">
      <section className="copy">
        <h1>Let's keep the synergy</h1>
        <p>Inspiring people to do the things that inspire them is our mission.</p>

        <li className="link2" style={{marginTop: "25px"}}><Link to="/" className="nav__btn2">Back To Home</Link></li>
      </section>
    </div>
    <div className="right">
      <form>
        <section className="copy">
          <h2>Sign Up</h2>
          <div className="login-container">
            <p>Already have an account? <Link to="/login">
              <strong>Sign In</strong>
            </Link></p>
          </div>
        </section>

        <div className="input-container name">
          <label for="fname">First Name</label>
          <input id="fname" type="text" name="fname" placeholder="First Name" />
        </div>

        <div className="input-container name">
          <label for="lname">Last Name</label>
          <input id="lname" type="text" name="lname" placeholder="Last Name" />
        </div>

        <div className="input-container email">
          <label for="wemail">Work email</label>
          <input id="wemail" type="email" name="wemail" placeholder="Work email"/>
        </div>

        <div className="input-container name">
          <label for="jtitle">Job Title</label>
          <input id="jtitle" type="text" name="jtitle" placeholder="Job title: AWS MVP CTO" />
        </div>

        <button className="signup-btn" type="submit">
          Sign Up
        </button> 

        <section className="copy legal">
          <p><span className="small">
            By continuing, you agree to accept our <br /> <a href="">Privacy Policy</a> &amp; <a href="">
              Terms of Service
            </a>.
          </span></p>
        </section>      
      </form>
    </div>
  </div>
    </Fragment>
		)
	}
}

export default Register;